import React, { useState } from "react";
import SEO from "../../components/seo";
import H1 from "../../components/h1";
import Par from "../../components/Par";

const ChecklistItem = ({ name, done, text, toggleChecked }) => {
	const className = done.includes(name) ? "line-through" : "";
	const toggle = () => toggleChecked(name);
	return (
		<li className={`mb-3 ${className}`}>
			<label className="flex items-center" htmlFor={name}>
				<input id={name} className="mr-2 w-6 h-6" type="checkbox" onClick={toggle} onKeyPress={toggle} />
				<span>{text}</span>
			</label>
		</li>
	);
};

const FirstVisit = () => {
	const [done, setDone] = useState([]);
	const toggleChecked = name => {
		if (done.includes(name)) {
			setDone(done.filter(el => el !== name));
		} else {
			setDone([name, ...done]);
		}
	};
	return (
		<div>
			<SEO
				title="First Visit"
				keywords={[
					`First visit`,
					`Information`,
					`Things to bring`,
					`Checklist`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description="Checklist to help you with your first visit"
			/>
			<H1 text="First Visit" />
			<Par text="Please call our rooms on (08) 9389 1733 to schedule an appointment with one of our specialists." />
			<p>Please ensure that you have:</p>
			<ul className="list-reset mb-6 mt-3">
				<ChecklistItem name="1" done={done} text="A list of all medications" toggleChecked={toggleChecked} />
				<ChecklistItem name="2" done={done} text="Any prior blood tests" toggleChecked={toggleChecked} />
				<ChecklistItem name="3" done={done} text="X-rays or previous reports" toggleChecked={toggleChecked} />
				<ChecklistItem name="4" done={done} text="Medicare card" toggleChecked={toggleChecked} />
				<ChecklistItem name="5" done={done} text="Private health fund details" toggleChecked={toggleChecked} />
			</ul>
			<h2>Doctors referral</h2>
			<p>Please ensure to bring your doctor&apos;s referral with you</p>
		</div>
	);
};

export default FirstVisit;
